import * as React from "react";
import { graphql, Link } from "gatsby";
import Pagination from "../components/pagination";
import SEO from "../components/seo";

export default function BlogListPage(props) {
  const { data } = props;

  return (
    <>
      {/* eslint-disable-next-line react/jsx-pascal-case */}
      <SEO />
      <div className="posts">
        {data.allMarkdownRemark.nodes.map(({ frontmatter }) => (
          <article key={frontmatter.slug}>
            <header>
              <h1>
                <Link to={frontmatter.slug} className={frontmatter.className}>
                  {frontmatter.title}
                </Link>
                <small>Posted on: {frontmatter.date}</small>
              </h1>
            </header>
            <p>{frontmatter.excerpt}</p>
            <footer>
              <Link to={frontmatter.slug} className={frontmatter.className}>
                Read more...
              </Link>
            </footer>
          </article>
        ))}
      </div>

      <Pagination pageInfo={data.allMarkdownRemark.pageInfo} />
    </>
  );
}

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      limit: $limit
      skip: $skip
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        html
        frontmatter {
          className
          date(formatString: "MMMM DD, YYYY")
          excerpt
          slug
          title
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
      }
    }
  }
`;
