import * as React from "react";
import { Link } from "gatsby";

export default function Pagination({ pageInfo }) {
  const renderPagination = () => {
    const pageLinks = [];

    for (let i = 1; i <= pageInfo.pageCount; i++) {
      const key = `pagination-item-${i}`;

      if (i === pageInfo.currentPage) {
        pageLinks.push(
          <li key={key} className="active">
            <span>{i}</span>
          </li>
        );
      } else {
        pageLinks.push(
          <li key={key}>
            <Link to={i === 1 ? "/" : `/blog/${i}`}>{i}</Link>
          </li>
        );
      }
    }

    return pageLinks;
  };

  return (
    <>
      <nav className="pagination">
        <ul>
          {pageInfo.hasPreviousPage ? (
            <li>
              <Link to={`/blog/${pageInfo.currentPage - 1}`}>Previous</Link>
            </li>
          ) : (
            <li className="disabled">
              <span>Previous</span>
            </li>
          )}

          {renderPagination()}

          {pageInfo.hasNextPage ? (
            <li>
              <Link to={`/blog/${pageInfo.currentPage + 1}`}>Next</Link>
            </li>
          ) : (
            <li className="disabled">
              <span>Next</span>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
}
